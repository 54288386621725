import { TFunction } from "i18next";
import Yup from "../../utils/customYup";
import { StatsRequestBody } from "../../types/request/StatsRequest";

export function createExportSchema(t: TFunction): Yup.Schema<StatsRequestBody> {
    return Yup.object().shape({
        endDate: Yup.date().typeError(t("required")),
        startDate: Yup.date().typeError(t("required")),
        journalCaisse: Yup.boolean().required(),
        bordereauCaisse: Yup.boolean().required(),
        ventilationFiscale: Yup.boolean().required(),
        recapRecouvrement: Yup.boolean().required(),
        etatRecouvrement: Yup.boolean().required(),
        etatVersement: Yup.boolean().required(),
        registreCaisse: Yup.boolean().required(),
        duplicataQuitance: Yup.boolean().required(),
        etatDesConstatations: Yup.boolean().required(),
        recapRecouvrementParCaisse: Yup.boolean().required(),
        etatRecouvrementParCaisse: Yup.boolean().required(),
    });
}